import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "routing"
    }}>{`Routing`}</h1>
    <p>{`For routing we use `}<a parentName="p" {...{
        "href": "https://reactrouter.com/"
      }}>{`React Router`}</a>{`.`}</p>
    <p>{`It's become the de-facto standard unless you're using a framework like Gatsby or NextJS. They use derivatives of react router.`}</p>
    <p>{`Declarative routing works by rendering `}<inlineCode parentName="p">{`<Route>`}</inlineCode>{` components with a path prop. The router matches these to your URL and decides what to render. Completely client-side.`}</p>
    <h2 {...{
      "id": "exercise"
    }}>{`Exercise`}</h2>
    <p>{`Make it so users can browse through breeds of doggos.`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media1.giphy.com/media/wNlks0ID1igO4/giphy-loop.mp4?cid=4ac046a2zyn453sijtqiq3xrf9e1tjgbdp4x79moc74a7ret&rid=giphy-loop.mp4",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p>{`List all breeds on the homepage. Use `}<inlineCode parentName="p">{`https://dog.ceo/api/breeds/list/all`}</inlineCode>{` – you can ignore the 2nd layer of results.`}</p>
    <p>{`Link each breed to its own page that renders `}<inlineCode parentName="p">{`<Doggos />`}</inlineCode>{`.`}</p>
    <p>{`You'll need to wrap the app in `}<inlineCode parentName="p">{`<Router>`}</inlineCode>{`, then use a `}<inlineCode parentName="p">{`<Switch>`}</inlineCode>{`. Change `}<inlineCode parentName="p">{`<Doggos />`}</inlineCode>{` so it reads the breed from params.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const history = createBrowserHistory()

// ...

;<Router history={history}>
  {/* ... */}
  <Switch>
    <Route path="/:breed" children={Doggos} />
    <Route path="/" children={BreedList} />
  </Switch>
</Router>

// ...
const { breed } = useParams<{ breed: string }>()
`}</code></pre>
    <p>{`You can then use this breed param in your query.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`useQuery(["breeds", breed], async () => fetchBreed)
`}</code></pre>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p>{`You can find my solution at `}<a parentName="p" {...{
        "href": "https://github.com/AskTia/tia-react-train/tree/solution-routing"
      }}>{`https://github.com/AskTia/tia-react-train/tree/solution-routing`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      